<template>
    <div>
        <v-layout pb-16 wrap justify-center>
            <v-snackbar v-model="showSnackBar" color="black">
                <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                        <span style="color: white">
                            {{ msg }}
                        </span>
                    </v-flex>
                    <v-flex text-right>
                        <v-btn small :ripple="false" text @click="showSnackBar = false">
                            <v-icon style="color: white">mdi-close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-snackbar>
            <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#13736f"
                spinner="spinner" />
            <v-flex xs12 text-center>

                <v-layout v-if="list.length > 0" wrap pt-2 class="dmsansregular" style="color: black; font-size: 14px; ">
                    <v-flex xs12 pb-3 v-for="(item, i) in list" :key="i">
                        <v-card class="pa-3">
                            <v-layout wrap justify-space-between align-center>
                                <v-flex xs2>
                                    <v-icon v-if="item.isCurrent" color="green" size="130%">mdi-check-circle</v-icon>
                                </v-flex>
                                <v-flex xs6>
                                    <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: 600">{{
                                        item.name }}</span>
                                    <br />
                                    <span>{{ item.address }}<br /></span>
                                    {{ item.city }}
                                    {{ item.state }}
                                    -{{ item.pincode }}<br />
                                    <span>Contact: </span><span>{{ item.phone }},&nbsp;</span>
                                    <span>{{ item.email }}</span>
                                </v-flex>

                                <v-flex xs2>
                                    <v-icon size="130%" color="#13736f" style="cursor: pointer"
                                        @click="(editdailog = true), (curid = item)">mdi-pencil</v-icon>
                                </v-flex>
                                <v-flex xs2>
                                    <v-icon size="130%" color="red" style="cursor: pointer"
                                        @click="(deletedialog = true), (curid = item._id)">mdi-delete</v-icon>
                                </v-flex>

                            </v-layout>
                        </v-card>
                    </v-flex>
                </v-layout>
                <v-layout class="dmsansregular" style="color: black; font-size: 14px; " v-else wrap>
                    <v-flex xs12 text-center>
                        No address found !
                    </v-flex>
                </v-layout>

                <v-dialog :retain-focus="true" persistent v-model="dialog2" max-width="650px">
                    <v-card class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
                        <v-layout wrap justify-center>
                            <v-flex><v-layout pa-4 wrap justify-center>
                                    <v-flex xs12 text-right>
                                        <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                                            mdi-close
                                        </v-icon>
                                    </v-flex>

                                    <v-flex xs5 pt-4 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Name</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="name" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-4>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Email</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                v-model="email" hide-details :rules="emailRules"></v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pt-3 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Mobile Number</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="phone" hide-details :rules="phoneRules"></v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Pincode</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="number" v-model="pincode" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pt-3 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">City</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="city" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            State</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="state" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pt-3 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Landmark</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="landmark" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Route Description</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="routeDescription" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs10 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Address</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" type="text"
                                                v-model="address" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs10 text-right pt-4>
                                        <v-btn @click="validateInput()" dark width="100px" color="#13736f">
                                            <span style="color: white; font-size: 14px"> Add </span>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-dialog>
                <v-dialog :retain-focus="true" persistent v-model="removedialogue" max-width="600px">
                    <v-card>
                        <v-card-title text-center class="dmsansregular"
                            style="color: black; font-size: 16px; font-weight: lighter">
                            <v-layout wrap justify-center>
                                <v-flex xs12 text-center>
                                    Are you sure you want to remove this product from Cart?
                                </v-flex>
                            </v-layout>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="black" text @click="removedialogue = false" class="dmsansregular no-uppercase"
                                style="color: black; font-size: 16px; font-weight: lighter">Cancel</v-btn>
                            <v-btn color="red" class="dmsansregular no-uppercase"
                                style="color: black; font-size: 16px; font-weight: lighter" text
                                @click="removeItem()">Ok</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog :retain-focus="true" persistent v-model="editdailog" max-width="650px">
                    <v-card class="dmsansregular" style="color: #000000; font-size: 16px; font-weight: 500">
                        <v-layout wrap justify-center>
                            <v-flex><v-layout pa-4 wrap justify-center>
                                    <v-flex xs12 text-right>
                                        <v-icon @click="editdailog = false" color="red" size="150%" dark>
                                            mdi-close
                                        </v-icon>
                                    </v-flex>

                                    <v-flex xs5 pt-4 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Name</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="curid.name" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-4>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Email</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                v-model="curid.email" hide-details :rules="emailRules"></v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pt-3 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Mobile Number</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="curid.phone" hide-details
                                                :rules="phoneRules"></v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Pincode</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="number" v-model="curid.pincode" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pt-3 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">City</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="curid.city" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            State</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="curid.state" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pt-3 pr-5>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Landmark</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="curid.landmark" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs5 pl-5 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Route Description</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" dense
                                                type="text" v-model="curid.routeDescription" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs10 pt-3>
                                        <span style="color: black; font-size: 16px; font-weight: bold">
                                            Address</span>
                                        <v-form @submit.prevent="validateInput">
                                            <v-text-field color="#13736f" outlined background-color="white" type="text"
                                                v-model="curid.address" hide-details>
                                            </v-text-field>
                                        </v-form>
                                    </v-flex>

                                    <v-flex xs10 text-right pt-4>
                                        <v-btn @click="editAddress()" dark width="100px" color="#13736f">
                                            <span style="color: white; font-size: 14px"> Edit </span>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-dialog>

                <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
                    <v-card>
                        <v-card-title class="dmsansregular" style="color: black; font-size: 16px; font-weight: lighter">
                            <v-layout wrap justify-center>
                                <v-flex xs12 text-center>
                                    Are you sure you want to remove this Address?
                                </v-flex>
                            </v-layout>
                        </v-card-title>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="black" text @click="deletedialog = false" class="dmsansregular no-uppercase"
                                style="color: black; font-size: 16px; font-weight: lighter">Cancel</v-btn>
                            <v-btn color="red" class="dmsansregular no-uppercase"
                                style="color: black; font-size: 16px; font-weight: lighter" text
                                @click="deleteItem()">Ok</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-flex>
        </v-layout>
    </div>
</template>
    
    
<script>
import axios from "axios";
export default {
    data() {
        return {
            page: 1,
            currentpage: 1,
            showSnackBar: false,
            msg: null,
            deliveryAddress: {},
            list: [],
            editdailog: false,
            deletedialog: false,
            showid: {},
            curid: [],
            totalTax: null,
            totalAmount: null,
            deliveryCharge: null,
            amountPayable: null,
            removedialogue: false,
            selectedAddress: {},
            delivery: {},
            limit: 10,
            selectedAddressIndex: -1,
            appLoading: false,
            pages: 0,
            orderId: {},
            icon: null,
            emailRules: [
                (v) => !!v || "Email is required",
                (v) => /.+@.+\..+/.test(v) || "Valid email is required",
            ],
            phoneRules: [
                (v) => !!v || "Phone number is required",
                (v) => /^[0-9]+$/.test(v) || "Only digits are allowed",
                (v) => (v && v.length === 10) || "Phone number must be 10 digits",
            ],
            name: null,
            email: null,
            phone: null,
            address: null,
            pincode: null,
            city: null,
            state: null,
            landmark: null,
            routeDescription: null,
            cart: [],
            item: [],

            dialog2: false,
        };
    },


    beforeMount() {
        this.getAddress();
    },
    loadData() {
        this.getAddress();
        this.getCart();
    },
    methods: {
        clearSelectedAddress() {
            this.selectedAddressIndex = -1;
            this.selectedAddress = null;
        },
        deleteItem() {
            axios({
                url: "/deliveryAddress/delete/" + this.curid,
                method: "POST",
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status == true) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.deletedialog = false;
location.reload();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },










        editAddress() {
            axios({
                method: "POST",
                url: "/deliveryAddress/edit",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    id: this.curid._id,
                    name: this.curid.name,
                    email: this.curid.email,
                    phone: this.curid.phone,
                    address: this.curid.address,
                    pincode: this.curid.pincode,
                    city: this.curid.city,
                    state: this.curid.state,
                    landmark: this.curid.landmark,
                    routeDescription: this.curid.routeDescription,
                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        this.editdailog = false;
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        location.reload();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },





        getData() {
            axios({
                method: "GET",
                url: "/cart/list",
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.totalAmount = response.data.totalAmount;
                    this.amountPayable = response.data.amountPayable;
                    this.deliveryCharge = response.data.deliveryCharge;
                    this.totalTax = response.data.totalTax;
                })

                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },



        editDeliveryaddress(item) {
            axios({
                method: "POST",
                url: "/deliveryAddress/current/update",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    deliveryAddressId: item,
                },
            })
                .then((response) => {
                    if (response.data.status === true) {
                        this.getCart();
                    } else {
                        console.log("Editing cart failed: " + response.data.msg);
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
        getAddress() {
            this.appLoading = true;
            axios({
                method: "GET",
                url: "/deliveryAddress/list",
                headers: {
                    token: localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    this.list = response.data.data;
                    const foundAddressIndex = this.list.findIndex(
                        (item) => item.isCurrent === true
                    );
                    if (foundAddressIndex !== -1) {
                        this.selectedAddressIndex = foundAddressIndex;
                    }

                    const index = this.list.findIndex(
                        (item) => item._id === this.deliveryAddress._id
                    );

                    const foundAddress = this.list.find(
                        (item) => item._id === this.deliveryAddress._id
                    );
                    if (index !== -1) {
                        this.selectedAddressIndex = index;
                    }

                    if (foundAddress) {
                        this.deliveryAddress = foundAddress;
                        this.showid = foundAddress._id;
                    }
                    this.appLoading = false;
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>
    
    
<style scoped>
.no-uppercase {
    text-transform: none !important;
}
</style>